<div class="panel-container">
    <div id="left-container" class="left-container" #leftContainer>
        <!-- List Panel -->
        <div id="list-panel" class="list-panel" #listPanel>
            <div class="list-container">
                <div class="list-top" #listTop>
                    <div class="form-inline">
                        <!-- Search -->
                        <div class="form-group mb-3 searchBox">
                            <label class="sr-only" for="searchTerm">{{ "FILTER" | translate }}</label>
                            <div class="input-group">
                                <input
                                    class="form-control"
                                    type="text"
                                    id="searchTerm"
                                    name="searchTerm"
                                    [(ngModel)]="searchTerm"
                                    placeholder="{{ 'FILTER' | translate }}"
                                    title="{{ 'BOOLEAN_SEARCH_SUPPORTED' | translate }}"
                                />
                                <button class="btn btn-outline-light" type="button" (click)="resetSearch()" *ngIf="searchTerm && searchTerm.length > 0">
                                    <fa-icon icon="times" size="sm"></fa-icon>
                                </button>
                            </div>
                        </div>

                        <!-- Buttons -->
                        <div class="form-group mb-3 ms-auto">
                            <button type="button" class="btn btn-outline-primary me-2" routerLink="/{{ urls.configuration.notifications }}/kafka/new">
                                <fa-icon icon="plus" size="sm"></fa-icon>
                                <span class="d-none d-sm-inline-block ms-1">{{ "ADD" | translate }}</span>
                            </button>
                            <button type="button" class="btn btn-outline-secondary" (click)="refresh()" title="{{ 'REFRESH' | translate }}">
                                <fa-icon icon="sync-alt" size="sm" [spin]="refreshing"></fa-icon>
                                <span class="d-none d-sm-inline-block ms-1">{{ "REFRESH" | translate }}</span>
                            </button>
                        </div>
                    </div>
                </div>

                <!-- Table -->
                <div class="list-content" #listContent>
                    <div class="list-content" #listContent>
                        <div class="table-responsive">
                            <table class="table table-sm bordered m-0">
                                <thead>
                                    <tr>
                                        <th
                                            scope="col"
                                            sortable="name"
                                            (sort)="onSort($event, 'NAME')"
                                            [sortIf]="sortColumn === 'name'"
                                            [sortDirection]="sortDirection"
                                        >
                                            {{ "NAME" | translate }}
                                        </th>
                                        <th
                                            scope="col"
                                            sortable="topic"
                                            (sort)="onSort($event, 'TOPIC')"
                                            [sortIf]="sortColumn === 'topic'"
                                            [sortDirection]="sortDirection"
                                        >
                                            {{ "TOPIC" | translate }}
                                        </th>
                                        <th
                                            scope="col"
                                            sortable="servers"
                                            (sort)="onSort($event, 'SERVERS')"
                                            [sortIf]="sortColumn === 'servers'"
                                            [sortDirection]="sortDirection"
                                        >
                                            {{ "SERVERS" | translate }}
                                        </th>
                                        <th scope="col" translate>EVENTS</th>
                                        <th scope="col" translate>SCTE_35</th>
                                        <th scope="col" translate></th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="(kafkaConfigs$ | async) && !loading">
                                    <tr *ngFor="let kafka of kafkaConfigs$ | async">
                                        <td>
                                            <ngb-highlight title="kafka.name" [result]="kafka.name" [term]="searchTermArray"></ngb-highlight>
                                        </td>
                                        <td>
                                            <ngb-highlight title="kafka.topic" [result]="kafka.topic" [term]="searchTermArray"></ngb-highlight>
                                        </td>
                                        <td>
                                            <ngb-highlight title="kafka.servers" [result]="kafka.servers" [term]="searchTermArray"></ngb-highlight>
                                        </td>
                                        <td>
                                            <fa-icon *ngIf="kafka.send_events" icon="check" size="md"></fa-icon>
                                        </td>
                                        <td>
                                            <fa-icon *ngIf="kafka.send_scte" icon="check" size="md"></fa-icon>
                                        </td>
                                        <td class="w-80px">
                                            <a href="javascript:void(0)" title="{{ 'TEST' | translate }}" (click)="test(kafka)">
                                                <fa-icon [icon]="['far', 'badge-check']" size="sm"></fa-icon>
                                                <span class="sr-only">{{ "TEST" | translate }}</span>
                                            </a>
                                            <a class="ms-3" href="javascript:void(0)" title="{{ 'EDIT' | translate }}" (click)="edit(kafka)">
                                                <fa-icon icon="pencil" size="sm"></fa-icon>
                                                <span class="sr-only">{{ "EDIT" | translate }}</span>
                                            </a>
                                            <a class="ms-3 text-danger" href="javascript:void(0)" title="{{ 'DELETE' | translate }}" (click)="delete(kafka)">
                                                <fa-icon icon="trash-alt" size="sm"></fa-icon>
                                                <span class="sr-only">{{ "DELETE" | translate }}</span>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="(total$ | async) === 0 && kafkaConfigs.length">
                                    <tr>
                                        <td colspan="5" class="text-center">{{ "NO_RESULTS" | translate }}</td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="!kafkaConfigs.length && !loading">
                                    <tr>
                                        <td colspan="5" class="text-center">{{ "NO_KAFKA_CONFIGS" | translate }}</td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="(!kafkaConfigs && loading) || (kafkaConfigs && loading)">
                                    <tr>
                                        <td colspan="5" class="text-center">{{ "LOADING" | translate }}...</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <!-- Pagination -->
                    <div class="list-bottom" #listBottom>
                        <app-pagination
                            [startNum]="startNum"
                            [endNum]="endNum"
                            [total]="total$ | async"
                            [selectedPageSize]="selectedPageSize"
                            [(page)]="page"
                            [pageSize]="pageSize"
                            (pageSizeChange)="pageSizeChanged($event)"
                        ></app-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
