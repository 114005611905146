import { Injectable, inject } from "@angular/core";
import { InferredAPIRequests } from "@zixi/zen-rpc";
import { share, map, catchError, of, from } from "rxjs";
import { ZenApiService } from "src/app/services/zen-rpc-service";

@Injectable({
    providedIn: "root"
})
export class PagerDutyService {
    private zenApi = inject(ZenApiService);
    pagerDuties$ = from(this.zenApi.client.pagerDuty.list()).pipe(
        share(),
        catchError(_ => of<false>(false)),
        map(response => (response && response.body.success ? response.body.result : false))
    );

    async createPagerDuty(body: InferredAPIRequests["pagerDuty"]["create"]["body"]) {
        try {
            const result = await this.zenApi.client.pagerDuty.create({ body });
            return result.body.result;
        } catch (err) {
            return false;
        }
    }
    async updatePagerDuty(body: InferredAPIRequests["pagerDuty"]["update"]["body"], id: number) {
        try {
            const result = await this.zenApi.client.pagerDuty.update({ body, params: { id } });
            return result.body.result;
        } catch (err) {
            return false;
        }
    }
}
