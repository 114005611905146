import { Injectable } from "@angular/core";
import { Observable, ReplaySubject, from, of } from "rxjs";
import { map, share } from "rxjs/operators";

import { TranslateService } from "@ngx-translate/core";
import { IncidentsConfig } from "../../../../models/shared";

import { AuthService } from "src/app/services/auth.service";
import { ZenApiService } from "src/app/services/zen-rpc-service";
import { InferredAPIRequests } from "@zixi/zen-rpc";

@Injectable({
    providedIn: "root"
})
export class IncidentConfigService {
    incidentsConfig: Observable<IncidentsConfig>;
    private incidentsConfig$: ReplaySubject<IncidentsConfig>;
    private dataStore: {
        incidentsConfig: IncidentsConfig;
    };

    constructor(
        private authService: AuthService,
        private translate: TranslateService,
        private zenApiService: ZenApiService
    ) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    private reset() {
        this.dataStore = {
            incidentsConfig: undefined
        };

        this.incidentsConfig$ = new ReplaySubject(1) as ReplaySubject<IncidentsConfig>;
        this.incidentsConfig = this.incidentsConfig$.asObservable();
    }

    private prepIncidentConfig(item: IncidentsConfig) {
        return item;
    }

    private updateStore(incidentConfig: IncidentsConfig, merge: boolean): void {
        this.prepIncidentConfig(incidentConfig);

        if (merge) {
            const current = this.dataStore.incidentsConfig;

            this.dataStore.incidentsConfig = Object.assign(current || {}, incidentConfig);

            const relationships = [];
            relationships.forEach(overwrite => {
                if (current[overwrite.id] == null) current[overwrite.obj] = null;
            });
        } else {
            this.dataStore.incidentsConfig = incidentConfig;
        }
    }

    refreshIncidentsConfig(): Observable<IncidentsConfig> {
        const incidentsConfig$ = from(this.zenApiService.client.configuration.getIncidents()).pipe(share());
        incidentsConfig$.subscribe(
            data => {
                if (data.body.success) {
                    const incidentConfig = { ...data.body.result, notify: !!data.body.result.notify };

                    this.updateStore(incidentConfig, true);

                    this.incidentsConfig$.next(Object.assign({}, this.dataStore).incidentsConfig);
                }
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_TRANSCODING_PROFILES"), error)
        );

        return incidentsConfig$.pipe(map(r => ({ ...r.body.result, notify: !!r.body.result.notify })));
    }

    async upsertIncidentsConfig(
        model: InferredAPIRequests["configuration"]["upsertIncidents"]["body"]
    ): Promise<boolean> {
        try {
            const result = await this.zenApiService.client.configuration.upsertIncidents({ body: model });
            if (result.status !== 200) return false;

            this.updateStore(model, false);
            this.incidentsConfig$.next(Object.assign({}, this.dataStore).incidentsConfig);

            return true;
        } catch (error) {
            return false;
        }
    }
}
